<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="600px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="150px">
      <el-form-item label="指标编码"  prop="indexCode">
        <el-input v-model="form.indexCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="指标名称" prop="indexName">
        <el-input v-model="form.indexName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="指标英文名称" prop="indexNameEn">
        <el-input v-model="form.indexNameEn" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="指标描述">
        <el-input v-model="form.indexDesc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="指标类型编码">
        <el-input v-model="form.indexType" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="指标类型名称">
        <el-input v-model="form.indexTypeName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="指标显示前缀">
        <el-input v-model="form.displayPrefix" style="width: 80px;"/>
      </el-form-item>
      <el-form-item label="指标显示后缀">
        <el-input v-model="form.displaySuffix" style="width: 80px;"/>
      </el-form-item>
      <el-form-item label="小数位">
        <!-- <el-input v-model="form.displayPoint" style="width: 80px;"/> -->
        <el-select v-model="form.displayPoint" clearable placeholder="请选择" style="width: 100px;">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指标类型" prop="indexCategory">
        <el-radio-group v-model="form.indexCategory">
          <el-radio :label="0">公用</el-radio>
          <el-radio :label="1">交易者</el-radio>
          <el-radio :label="2">跟随者</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否多选" prop="selectionType">
        <el-radio-group v-model="form.selectionType">
          <el-radio :label="1">单选</el-radio>
          <el-radio :label="2">多选</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否包括不限选项" prop="selectionLimit">
        <el-radio-group v-model="form.selectionLimit">
          <el-radio :label="1">有无限选项</el-radio>
          <el-radio :label="2">无无限选项</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label='1'>有效</el-radio>
        <el-radio v-model="form.status" label='2'>无效</el-radio>
      </el-form-item>
      <el-form-item label="备注">
        <el-input  type="textarea" autosize v-model="form.remark" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srSignalIndexList'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,rules:[],
      form: {
        id: '',
        indexCode: '',
        indexName: '',
        indexNameEn: '',
        indexDesc: '',
        indexType: '',
        indexTypeName: '',
        displayPrefix: '',
        displaySuffix: '',
        displayPoint: '',
        indexCategory: 0,
        selectionType: 1,
        selectionLimit: 1,
        status: '1',
        remark: ''
      },
      rules: {
        indexCode: [
          { required: true, message: '请输入指标编码', trigger: 'blur' },
          { min: 2, max: 50, message: "长度在 2 到 50 个字符(25个汉字)", trigger: "blur" }
        ],
        indexName: [
          { required: true, message: '请输入指标名称', trigger: 'blur' },
          { min: 2, max: 50, message: "长度在 2 到 50 个字符(25个汉字)", trigger: "blur" }
        ],
        indexNameEn: [
          { required: true, message: '请输入指标英文名称', trigger: 'blur' },
          { min: 2, max: 50, message: "长度在 2 到 50 个字符", trigger: "blur" }
        ],
        indexCategory: [
          { required: true, message: '请选择指标类型', trigger: 'blur' }
        ],
        selectionType: [
          { required: true, message: '请选择selectionType', trigger: 'blur' }
        ],
        selectionLimit: [
          { required: true, message: '请选择selectionType', trigger: 'blur' }
        ],
      },
      options: [{
          value: '0',
          label: '0'
        }, {
          value: '1',
          label: '1'
        }, {
          value: '2',
          label: '2'
        }, {
          value: '3',
          label: '3'
        }, {
          value: '4',
          label: '4'
        }],
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd();
          } else {
            this.doEdit();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        indexCode: '',
        indexName: '',
        indexNameEn: '',
        indexDesc: '',
        indexType: '',
        indexTypeName: '',
        displayPrefix: '',
        displaySuffix: '',
        displayPoint: '',
        status: '1',
        remark: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
